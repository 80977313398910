import React, { useRef, useEffect } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber'; // Импортируйте useThree
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as THREE from 'three';
// Сделать панель со справкой по управлению и возможно доп настройку сцены.
// Define the planets data
const planets = [
  { name: 'Glacio', radius: 1, position: [-5, 0, 0], color: 0x00cdff, emissive: 0x00cdff },
  { name: 'Desolo', radius: 0.5, position: [5, 0, 0], color: 0x9bb870, emissive: 0x9bb870 },
  { name: 'Vesania', radius: 1, position: [0, 0, -5], color: 0xfe00fe, emissive: 0xfe00fe },
  { name: 'Calidor', radius: 1, position: [0, 0, 5], color: 0xe76600, emissive: 0xe76600 },
  { name: 'Atrox', radius: 1, position: [0, -5, 0], color: 0x7fcd00, emissive: 0x7fcd00 },
  { name: 'Novus', radius: 0.5, position: [0, 5, 0], color: 0xdd9aff, emissive: 0xdd9aff },
  { name: 'Sylva', radius: 1, position: [0, 0, 0], color: 0x12a162, emissive: 0x12a162 },
  { name: 'Sun room', radius: 0.5, position: [-10, 0, 0], color: 0xffffff, emissive: 0xffffff },
];

// Planet component
const Planet = ({ position, radius, color, emissive }) => {
  const meshRef = useRef(null);
  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.rotation.y += 0.01;
    }
  });

  return (
    <mesh ref={meshRef} position={position}>
      <sphereGeometry args={[radius, 32, 32]} />
      <meshStandardMaterial color={color} emissive={emissive} emissiveIntensity={1} />
    </mesh>
  );
};

// Controls component
const Controls = () => {
  const { camera, gl } = useThree(); // Используйте useThree

  useEffect(() => {
    if (camera && gl) {
      const controls = new OrbitControls(camera, gl.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.25;
      controls.enableZoom = true;

      return () => {
        controls.dispose();
      };
    }
  }, [camera, gl]);

  return null;
};

// Scene component
const Scene = () => {
  return (
    <>
      {planets.map((planet) => (
        <Planet
          key={planet.name}
          position={planet.position}
          radius={planet.radius}
          color={planet.color}
          emissive={planet.emissive}
        />
      ))}
      <Controls />
    </>
  );
};

// App component
const App = () => {
  return (
    <Canvas
      camera={{ position: [-10, 0, 10], fov: 75 }}
      style={{ height: '100vh', width: '100%' }}
    >
      <Scene />
    </Canvas>
  );
};

export default App;
