import React, { useEffect, useState } from 'react';

const UserAvatar = () => {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    // Проверяем наличие объекта user_info в localStorage
    const cachedUserInfo = localStorage.getItem('user_info');
    if (cachedUserInfo) {
      setUserInfo(JSON.parse(cachedUserInfo));
    }
  }, []);

  return (
    <>
      {userInfo ? (
        <span>
        <a href='/@me'>
            <img
                src={`https://cdn.discordapp.com/avatars/${userInfo.id}/${userInfo.avatar}.png`}
                alt={`${userInfo.username}`}
                title={`${userInfo.username}`}
            />
        </a>
        </span>
      ) : (
        <a href='/@me' className="resources">Авторизоваться</a>
      )}
    </>
  );
};

export default UserAvatar;
