import React from 'react';
import ReactDom from 'react-dom/client';

function Content() {
    return (
        <>
        <h2>Фан контент по серверу/конкурсу</h2>
        <figure className="poster-cont">
                <img className="poster-img" src="https://astrocreate.space/static/img/posters/6season.png"/>
                <figcaption className="poster-capt">
                    <p>Автор: Gegs.</p>
                    <p>Создан для: конкурса постеров к 6 сезону.</p>
                    <p>P.s. Сделано в Paint.</p>
                </figcaption>
            </figure>
            <figure className="poster-cont">
                <img className="poster-img" src="https://astrocreate.space/static/img/posters/astrocreate_6_.png"/>
                <figcaption className="poster-capt">
                    <p>Автор: GλTTY.</p>
                    <p>Создан для: конкурса постеров к 6 сезону.</p>
                </figcaption>
            </figure>
            <figure className="poster-cont">
                <img className="poster-img" src="https://astrocreate.space/static/img/posters/darkass.png"/>
                <figcaption className="poster-capt">
                    <p>Автор: NIGGAS.</p>
                    <p>Создан для: Deep dark fantasies.</p>
                </figcaption>
            </figure>
        </>
    )
};

export default Content;