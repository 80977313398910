import React, { useState, useEffect } from 'react';
import './changelog.css';

const Changelog = () => {
  const [commits, setCommits] = useState([]);

  useEffect(() => {
    const fetchCommits = async () => {
      try {
        const response = await fetch('https://changelog.astrocreate.space/'); // Замените на URL вашего CF Worker
        if (!response.ok) throw new Error('Failed to fetch commits');
        const data = await response.json();
        console.log(data)
        setCommits(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCommits();
  }, []);

  return (
    <>
      {commits.map((commit, index) => (
        <div key={index} className="changelog-panel">
          <h3>{commit.message}</h3>
          <p><strong>Автор:</strong> {commit.author}</p>
          <p><strong>Дата:</strong> {new Date(commit.date).toLocaleString()}</p>
        </div>
      ))}
    </>
  );
};

export default Changelog;
