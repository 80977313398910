import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import AuthDiscord from './AuthDiscord';  // Импортируйте новый компонент
import TRender from './Render';

const root = ReactDOM.createRoot(document.getElementById('app-mount'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/@me" element={<AuthDiscord />} />
        <Route path="/@render" element={<TRender/>} />
        {/* Можно добавить другие маршруты */}
        {/* <Route path="/another-page" element={<AnotherComponent />} /> */}
      </Routes>
    </Router>
  </React.StrictMode>
);
