import React from 'react';
import ReactDom from 'react-dom/client';
import './bio.css';

function EB() {
    return (
        <p>
            <img src="https://i.redd.it/snoovatar/avatars/f094dffb-9b9d-4f8c-a5ce-953974cb6c45.png"/>
            <p><b>ElectricalBass, он же Саня - Бог астронира, мастер логики и механизмов, Строитель мега базы со стажем и т.д.</b></p>
            <p><b>1. Мега базу с огромным количеством автоматизации, ресурсов и стен. Из-за лагов сейв пришлось запускать на сервере чтобы продолжить хоть как-то играть. (Вес: ~100мб)</b></p>
            <p><b>2. Огромный</b> гладкий туннель идущий сквозь Сильву и Атрокс.</p>
            <p><b>3. Огромное</b> производство всех ресурсов по нажатию кнопки.</p>
            <p>4. Автоматизированное выращивание и сбор всех видов семян.</p>
            <p><b>И всё это водиночку!</b></p>
            <p>Подробнее можно чекнуть на его <a target="_blank" href="https://www.reddit.com/user/ElectricalBass6135/?sort=top">реддите</a></p>
        </p>
    );
};

function FF() {
    return (
        <p>
            <img src="./static/img/avatars/fire_fox_sticker_5.png"/>
            <p><b>Бог астронира, мастер механизмов, Строитель со стажем, Первооткрыватель расположения неопознанного спутника и многое другое, проще говоря: Мастер на все лапки.</b></p>
            <p><b>Вот что он сделал за всё время игры на разных сезонах:</b></p>
            <p>1. Постройка торговой площадки, на которой все активно выкупали земли.</p>
            <p>2. Нахождение местоположения неопознанного спутника и солнечной комнаты, организация экспедиции по активации неопознанного спутника на сервере без ракет.</p>
            <p>3. На сервере анархии дважды совершил подрыв базы <b>mr.xamster "Хомяка"</b> при помощи других ничего не подозревавших игроков, в отместку за подрыв базы Остапа, которая была обнаружена во время поисков моей базы на Новусе. Как оказалось позже, база Остапа была очень близко, потому что <b>Hamigaos#2517 "Хамигаос"</b> любезно включил демонстрацию, где показал его базу.</p>
            <p>4. Запрятал сокровище в размере <b>100 наноуглерода</b> на какой-то из планет.</p>
            <p>5. Организатор постройки Солнечного поезда, который был разобран через пол часа после начала стройки.</p>
            <p>6. Создатель самых крупных мини игр в Astroneer на одном сохранении, из когда-либо известных.</p>
        </p>
    );
};

function HG() {
    return (
        <p>
            <img src="./static/img/avatars/hamigaos1.png"/>
            <p><b>Бог астронира, первооткрыватель и исследователь багов.</b></p>
            <p>1. Создал первую межпланитарную жд.</p>
            <p>2. Открыл баг, с уменьшением и увеличением предметов. Что открыла нам огромные возможности, а также игроков.Так же этот баг отлично сочитается с другим багом, который позволяет ложить большие предметы в малые ячейки. </p>
            <p>3. Случайно открыл беспроводную энергию по платформам что дало тоже не мало крутых возможностей.</p>
            <p>4. Незнаю можно считать это чем-то новым, но по крайней мере этим никто раньше не пользовался. Я придумал необычное полёт на платформах, а именно, взрывом ТНТ, что позволяет упростить выживание если не иследованы ракеты и двигатели. И платформы на двигателях, <b>(если в игре отсутствует ракеты).</b></p>
        </p>
    );
};

function HM() {
    return (
        <p>
            <img src="./static/img/avatars/xamster.png"/>
            <p><b>Самый крутой игрок сервера с консоли.</b></p>
            <p>Создал: <b>"Культ ЛРД"</b> и один из самых больших <b>пересказов сервера.</b></p>
            <p><b>Пересказ сервера от его лица:</b></p>
            <h3>Глава 1. Начало и Анархия:</h3>
            <p>Тьма.  Экран загрузки. Появление в мире. И первое, что было увидено это анархия.
    Вокруг все друг друга убивают все, разбегаются по сторонам и пытаются выжить.
    Пытаюсь убежать и создать базу на колёсах. Создаю трактор на чей то базе беря нужные ресурсы и оставляя записку.
    Потом собрав всё нужно еду к следующей ближайшей дружелюбной базе там обитали два дружелюбных человека у которых я побыл и увидел аномалию ведь один из них мог класть даже большие вещи в маленькие хранилища(я думал что это клиентский мод)
    далее я у них обжился, познакомился и улучшил свою базу на колёсах. Потом прибыли они, дикие варвары которые по полной использовали отсутствие правил они прилетели на летательных аппаратах с огнестрельным оружием и уничтожили их базу я ели смог выжить и уехать. Я создал базу покрыв слоем земли кратер и покрасив слой земли для маскировки где оставил свою машину и начал копать под землю
    там я создал прекрасную базу с бесконечными ресами потом пришёл один из тех варваров с динамитом и подумав, что это сделал не я один, а мне помогали те люди,взорвал всё это, потому что враждовал с ними. Хотя потом когда он понял что, ошибся он, открылся как хороший человек и даже сначала не верил что эту базу я построил сам. Так как взрыв был сильный, но вещи не сломались, а лишь разлетелись и я положил их по местам.
    С того момента я жил в кратере хотя использовал ту же жилу астрониума. Потом после нескольких обменов я решил провести Ж/Д путь до начального убежища и на удивление никто не взрывал мою базу и туда поселились те кто, потеряли свои дома или нуждались в ресурсах и я начал создавать свой оркестр где танцевали авто руки(кстати я так и не доделал этот проект). Потом этот оазис спокойствия и благополучия кто-то взорвал(всё ещё ищу кто это был) я попытался восстановить, но всё опять сломали
    потом я обратился к сообществу в дискорде с идеей о том что будет хорошо создать идеальный мир где каждый делает что хочет и его базу не взрывают и всем хорошо. Многие согласились и начался 1 сезон мирного сервера.</p>
            <h3>Глава 2. Мирный мир:</h3>
            <p>Начало. Экран загрузки. Свет! Никто никого не убивает и все строят идеальную общую базу (хотя генератор кислорода кто-то потерял, но потом нашли),в этот момент шёл стрим который я смотрел попутно играя на сервере и в поиске ресурсов для общего блага и образцов для исследования я нашёл это.</p>
            <h3>Глава 3. Прекрасное создание:</h3>
            <p>Прекрасное создание,
    я увидел его. Милый светящийся чёрно белый шарик который так меня и манил. На нём был стикер который я не прочитал до конца там было что-то про, то что этот шар опасен ноя не поверил и взял его в руки. Я смотрел на него, а он на меня. И постояв и насладившись его видом я пошёл с ним на базу, что бы показать его другим и положить в безопасное место (хотя я его в итоге потерял).
    Вернувшись я его положил и мы продолжили развиваться. Ну тут ничего интересного просто развитие. Я решил не селится на начальной планете со скудными ресурсами и газами, а спешил поселиться на Гласио - ледяной планете полной холода железа и аргона. Вместе со мной полетел лис с которым я был знаком с анархии. Он немного помог мне развеваться, а потом вышел из игры и я сам ели как выживая создал базу на крыше телепорта, но передумал и расположил её снизу телепорта. Я построил фабрику на бесконечные ресы из астрониума и провёл Ж/Д путь к его залежам. И для того что бы получать газы которых не было на Гласио я обменивался с другими людьми и одним новичком. Огурка который думал что у нас всё ещё анархия :/ и спрятал свою базу и давал мне азот.
    На удивление лис так и не появлялся, а я уже создал прекрасную базу с кучей ветряков добычи газа и производства наноуглерода. И тут я вспомнил про то прекрасное создание. И создал сначала комнатку для трёх таких шаров и сделал помещение рядом которое планировалось как музей, но стало главным местом культа.</p>
            <h3>Глава 3,5 Вилли астро-койот и Дорожный астро-бегун:</h3>
            <p>Идея была в том что если игроку дать много Эноки то он станет супер быстрым и я и чел ник которого произнести я не могу, но он опишется где-то снизу. Мы вместе с ним решили скопировать легендарный мультик в игру и собрав нужные цвета для дороги полетели на Калидор где помучались со скином и обдумали саму идею где я буду койотом который использует опасные растения и взрывчатку, чтобы поймать дорожного астро-бегуна которым был он. Но, к сожалению ничего не вышло и этот проект, как и много других канул в лету.</p>
            <h3>Глава 4. Культ и как кончился этот сезон:</h3>
            <p>Я решил переоборудовать начальную комнатку в прихожую, а зал музея в зал для моего культа. Там была сцена с главными ЛРД и зал обычных и несколько сидений. К сожалению никто не разделял мою любовь к этим прекрасным созданиям(кроме одного человека который принёс мне целую кучу таких шаров. И ещё один который принёс мне кучу фигур для украшения их платформ. Хотя они наверняка просто хотели моих ресов ведь сами фабрику не смогли сделать) и никто не приходил туда кроме меня(хотя в конце сезона появился один новичок которому это было интересно. Но сезон кончился и мы не успели)
    ну хватит о грустном надо что-то весёлое сказать. На самом деле Лис всё ещё был на моей базе и помогал мне, но я его не замечал(хотя был активным игроком) и он набрав достойно почвы тем странным способом построил огромную платформу для рынка где все могли покупать и продавать свои ресурсы и валютой были полные детекторы отказа. К сожалению он зря всё это строил ведь у всех были фабрики на астрониуме и добыча газа так что никто ни с кем не обменивался.
    Потом вышло обновление и сервер сломался. Хотя потом починился, но так как обнова, решили сделать новый сезон.</p>
            <h3>Глава 5. 2 сезон мирного выживания:</h3>
            <p>Чтобы попасть на которое я стал бустером Остапа(30 рублей всего лишь)
    Начало. Экран загрузки. Появление. И нет начального убежища?????? Я запаниковал и быстро пустился к ближайшей базе где заряжалась кислородом и использовала их ресы для начала и посмотрев список рецептов увидел: Нет шаттлов=нет перемещения странным способом из-за чего я весь сезон пробыл на начальной планете ведь не умел его делать. Нет платформ обмена = нет фабрики на астрониуме. Нет генераторов кислорода для того что бы нечестные люди которые суют генераторы кислорода в рюкзаках так не могли делать. И ещё малюсенькие залежи ресурсов.
    Я понял что это будет сложно, но не достраивался и накопав цинка и алюминия в пещерах и сделал опять же ездящую базу поехал к 3 телепорту, чтобы попробовать сделать тот баг и полететь на Гласио и проехал мимо чей-то базы на телепорте немного там побыв отправился дальше. Я прибыл к телепорту и немного обустроился и сделал небольшую платформу не на телепорте, а между землёй и ним немного с боку. Потом туда приехали ребята тоже на своей передвижной базе и смогли отправиться на Гласио, но без меня:((((. Далее я построил примитивную базу, где ресурсы получались за почву которую я копал планетоходом и построил тыквенную базу, чтобы давался кислород по трубам.
    Потом я иногда обмениваясь с другими что бы получить ресурсы и газы с других планет построил себе красивую базу и не строил путь к ядру потому что повторюсь фабрику на астрониуме не сделать.</p>
            <h3>Глава 6. Телепорты и конец сезона:</h3>
            <p>Ох, чуть не забыл, так как шаттлов не было, я решил попробовать прокатится на межпланетном автомобиле водителям которого был вы не поверите. Тот самый варвар из анархии!!! Хотя сейчас он уже не был варваром. Он сначала за то что я дал ему бензин бесплатно свозил меня и других людей (они ему заплатили:)) на Дезоло где я добыл себе немного вольфрама, а потом уже лично для меня(и никто кроме меня и не покупал у него эту функцию) отвёз меня на Гласио для добычи железа. Я ему конечно заплатил. Хотя самое смешное что в итоге произошёл откат и это всё стёрлось хотя с ним поговорил что лучше опять перезапустить сервер ведь отсутствие кораблей и платформ обмена уж слишком усложняет игру.
    Дальше я хочу рассказать про то что я нашёл(или мне дали уж не помню) то самое прекрасное создание,которое я аккуратно положил на высокую платформу и когда грустил, подходил к нему. Так как переход на другие планеты был сложен и фабрики на астрониуме не было я решил накопать кучу почвы сделать пару хранилищ и положить их на большое хранилище и на каждый склад положить по 8 маленьких вентиляторов которые в итоге давали колоссальное количество энергии и на это я потратил только немного смолы и кучу керамики и времени.
    Потом в один день я услышал от своих друзей то что они вычислили, где находится место с помощью которого проходит телепортация через планеты и они захотели отправиться туда и пошёл с ними немного помучавшись с платформами вместо ракет и всё-таки попал к ним там был Езерон, Лис и Гриб(извините если кого забыл) и мы отправились на телепорт по пути к нему по космосу подлетев к нему на платформе которая в итоге пропала в космосе и многие(но не я ведь я не взял бесконечный кислород) добрались до телепорта и активировали его и теперь он работа и я посетил своего друга на Калидоре взяв с собой в гравитационный шар новичка, а шар в рюкзак и там потусовавшись (и в итоге потеряв шар) мы вышли в итоге и вроде ничего не происходило особенного он согласился быть моим соседом по базе, но пропал и потом сезон закрылся.</p>
            <h3>Глава 7. Финальный сезон:</h3>
            <p>Финальный сезон (на финале которого я так и не побыл вот я глупый! Хотя я знаю что следующим летом когда выйдет достаточно обнов мы опять сделаем сервер и на финале которого я точно буду:)))
    Ну начался он и там было два сервера основной и который работал только на стримах. Я мог играть только на стримах. И на самом первом там был На коте и Паратоксик которые так и не появлялись на других хотя идея с сервером стримеров была хорошая. Ну на стримах опять дватцать-пять я полетел на Глаисо, сделал там супер маленькую базу на астрониуме и ещё на начальной базе открыли баг с увеличением предметов.
    Потом когда я всё-таки смог заходить на основной сервер тоже я ели, основавшись, почти один и без помощи сам сделал себе мини базу которую мне заснул в рюкзак друг. И я с помощью уже открытых телепортов пошёл не на Гласио, а на Везанию. Там обосновал базу на астрониуме и в начале её создания использовал баг на бесконечную почву, но потом перешёл на астрониум. Ещё я ходил на базу того самого чела которому был интересен мой культ и там он сделал огромную дыру до самого ядра Дезоло и сделал свою базу вокруг ямы я до сих пор считаю что это супер круто выглядит. И поговорив с ним я вернулся к себе и в то время как он делал здание культа в горе я экспериментировал с увеличением вещей и пытался сделать такую же яму как у него, но у меня в итоге не вышло.
    И когда он строил здание для культа я решил пойти через телепорты на Новус и до конца попал в "воксель бан" и наконец-то! Мой культ провёл собрание. Но без меня.
    Потом прошёл финальный стрим и сервер кончился как и лето ну, то есть когда я это пишу есть ещё 11 дней, но так оно кончилось. Вся эта эпопея продлилась примерно полтора месяца.
    Спасибо Остапу и Всем другим кто играл на сервере и сделали такую дружную компанию, но всё кончается.
    Всех кто хотят, могут поправить мои исторические ошибки или переписать историю от своего лица буду рад выслушать.</p>
            <h3>Список упомянутых:</h3>
            <p><b>Остап</b> <i>(Тот самый варвар и создатель всего сервера).</i></p>
            <p><b>Васёк</b> <i>((x5dfg)Его помощник в дискорд сервере и на обычном тоже, а также разраб админ сайта и бота).</i></p>
            <p><b>Лис</b> <i>(Создатель Прекрасных Мини игр и мой домовой на 1 сезоне :))).</i></p>
            <p><b>Огурка</b> <i>(прикольный парень который думал что всё ещё анархия, просто прикольный чел).</i></p>
            <p><b>Готти</b> <i>(Первый мой со-культовец и создатель культа на 3 сезоне и тоже крутой чел).</i></p>
            <p><b>Чатланин</b> <i>(чел который снабжал меня ЛРД и тоже культист, как и я).</i></p>
            <p><b>Догма3</b> <i>(был на первом сезоне, а потом пропал, просто прикольный и душевный человек).</i></p>
            <p>А ещё парень ник которого я не могу написать <i>(он тот самый кто открыл баг для засовывания что угодно куда угодно на сервере)</i>,вроде всех сказал кого, забыл говорить, но мой мозг не может держать столько инфы за раз :)</p>
            <h3>Конец.</h3>
            <p>P.s Орф.ошибки исправлены x5dfg, заняло 2 часа.</p>
        </p>
    );
};

function PlayersBio() {
    return (
        <>
            <h2>Био/пересказ сервера от игроков</h2>
            <details name="how">
                <summary>Electric_Bass / ElectricalBass</summary>
                <EB/>
            </details>
            <details name="how">
                <summary>Fire_Fox / Fire_Fox_FF</summary>
                <FF/>
            </details>
            <details name="how">
                <summary>Hamigaos / Хамиг</summary>
                <HG/>
            </details>
            <details name="how">
                <summary>Mr.Mamster / Хомячёк</summary>
                <HM/>
            </details>
        </>
    )
};

export default PlayersBio;