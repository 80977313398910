// src/components/CountryInfo.js
import React, { useEffect, useState } from 'react';

function getCountryInfo(countryCode) {
  const countries = {
    UA: { name: 'Ukraine' },
    RU: { name: 'Russia' },
    DE: { name: 'Germany' },
    US: { name: 'United States' },
    FR: { name: 'France' },
    GB: { name: 'United Kingdom' },
    CN: { name: 'China' },
    JP: { name: 'Japan' },
    IN: { name: 'India' },
    BR: { name: 'Brazil' },
    AU: { name: 'Australia' },
    CA: { name: 'Canada' },
    IT: { name: 'Italy' },
    MX: { name: 'Mexico' },
    KR: { name: 'South Korea' },
    AR: { name: 'Argentina' },
    ES: { name: 'Spain' },
    ZA: { name: 'South Africa' }
  };

  return countries[countryCode] || { name: countryCode }; // Return name or code if not found
};

function CountryInfo() {
  const [countryInfo, setCountryInfo] = useState({ name: '' });

  useEffect(() => {
    async function fetchCountry() {
      try {
        const response = await fetch('https://worker.astrocreate.space/get_country');
        const data = await response.json();
        if (data.status === 'success') {
          const countryCode = data.country;
          setCountryInfo(getCountryInfo(countryCode)); // Convert code to name
        }
      } catch (error) {
        console.error('Ошибка при получении страны:', error);
        setCountryInfo({ name: 'US' }); // Default value
      }
    };
    fetchCountry();
  }, []);

  return <p>Ваша страна: {countryInfo.name}</p>;
};

export default CountryInfo;
