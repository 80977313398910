import React, { useEffect, useState } from 'react';
import {
	DiscordDefaultOptions,
	DiscordInteraction,
	DiscordMarkdown,
	DiscordMention,
	DiscordMessage,
	DiscordMessages,
	DiscordOptionsContext,
	DiscordReaction,
	DiscordReactions,
} from '@discord-message-components/react';
import '@discord-message-components/react/styles';

export default function Ds() {
	const [discordOptions, setDiscordOptions] = useState(null);
	const [userName, setUserName] = useState('Guest'); // Начальное значение "Guest"

	useEffect(() => {
		// Получение данных пользователя из кеша
		const userInfo = localStorage.getItem('user_info');
		if (userInfo) {
			const parsedUserInfo = JSON.parse(userInfo);
			setUserName(parsedUserInfo.username); // Установка имени пользователя
		}

		// Запрос к API для получения данных профиля бота
		fetch('https://worker.astrocreate.space/get_bot_profile')
			.then(response => response.json())
			.then(data => {
				if (data.status === 'success') {
					const { botProfile } = data;
					// Формирование discordOptions с данными из профиля
					const options = {
						...DiscordDefaultOptions,
						profiles: {
							bot: {
								author: botProfile.username,
								avatar: `https://cdn.discordapp.com/avatars/${botProfile.id}/${botProfile.avatar}.gif?size=2048`,
								roleColor: '#ffdf00',
							},
						},
					};
					setDiscordOptions(options);
				}
			})
			.catch(error => {
				console.error('Ошибка при получении профиля бота:', error);
			});
	}, []);

	if (!discordOptions) {
		// Пока данные загружаются, можно отобразить что-то вроде загрузочного индикатора
		return (
			<img className='image_cat' alt="ИЗВИНИТЕ, СПЕКТАКЛЯ НЕ БУДЕТ. МЫ ОБОССАЛИ РЕКВИЗИТ" src="https://i.pinimg.com/736x/19/3d/69/193d69a53f12b72eef63a63d248adefc.jpg"/>
		);
	}

	return (
		<DiscordOptionsContext.Provider value={discordOptions}>
			<DiscordMessages>
				<DiscordMessage bot={true} profile="bot">
					Привет <DiscordMention highlight={true}>{userName}</DiscordMention>!
					<DiscordMarkdown>
						{'\n'}
						||Ответы на часто задаваемые вопросы вы найдёте в этом дискорд боте|| 
					</DiscordMarkdown>
					<div slot="reactions">
						<DiscordReactions>
							<DiscordReaction name=":23:" image="./media/1123941446735646750.webp" count={69} active={true} />
						</DiscordReactions>
					</div>
				</DiscordMessage>
			</DiscordMessages>
		</DiscordOptionsContext.Provider>
	);
}
