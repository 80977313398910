import React from 'react';
import AuthCheck from '../microComponents/AuthCheck';
import './Header.css';

function Header({ setActiveSection }) {
    return(
        <header className="header">
            <a href="/">
                <img src="./favicons/favicon.png" alt="Astrocreate logo" />
            </a>
            <nav>
                <a rel="noreferrer" href="#project" onClick={() => setActiveSection('project')}>О проекте</a>
                <a rel="noreferrer" href="#changelog" onClick={() => setActiveSection('changelog')}>Изменения</a>
                <a rel="noreferrer" href="#mods" onClick={() => setActiveSection('mods')}>Моды</a>
                <a rel="noreferrer" href="#play" onClick={() => setActiveSection('play')}>Играть</a>
                <a rel="noreferrer" href="#bio" onClick={() => setActiveSection('bio')}>Био</a>
                <a rel="noreferrer" href="#content" onClick={() => setActiveSection('content')}>Контент</a>
            </nav>
            <AuthCheck/>
        </header>
    )
};

export default Header;
